.card-item {
  margin-bottom: 50px;
}
.card-item .note {
  margin-left: 16px;
}
.card-item .status-note {
  display: flex;
}
.card-item .status-note .status-label {
  width: 50px !important;
  color: #ff4d4f;
}
.card-item .status-note .status-content {
  min-width: 400px;
  color: #a4a4a4;
}
.card-item .action-time {
  color: #b2b2b2;
  margin-left: 20px;
}
