
.card-item {
  margin-bottom: 50px;
  .note {
    margin-left: 16px;
  }
  .status-note {
    display: flex;
    .status-label {
      width: 50px !important;
      color: #ff4d4f;
    }
    .status-content {
      min-width: 400px;
      color: #a4a4a4;
    }
  }
  .action-time {
    color: #b2b2b2;
    margin-left: 20px;
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;